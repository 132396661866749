import footerTecnoLogo from "../../resources/images/footer_tecno_logo.png";
import { Box, Link, SxProps, Theme } from "@mui/material";
import { Link as Scroll } from "react-scroll";

export const Footer = () => {
  return (
    <Box sx={sx} className="Footer">
      <Box className="Footer-Main">
        <Box className="Footer-Main-Tecno-Logo" component="img" alt="テクノスタンダード" src={footerTecnoLogo} />
        <Box className="Footer-Main-Post">
          〒150-0002
          <br />
          東京都渋谷区渋谷3-10-15 YKビル7F
        </Box>
        <Box className="Footer-Main-Index">
          <Scroll className="Footer-Main-Index-Link" to="About" smooth={true} duration={500}>
            <Box>ABOUT</Box>
          </Scroll>
          <Scroll className="Footer-Main-Index-Link" to="Service" smooth={true} duration={500}>
            <Box>SERVICE</Box>
          </Scroll>
          <Scroll className="Footer-Main-Index-Link" to="Company" smooth={true} duration={500}>
            <Box>COMPANY</Box>
          </Scroll>
          <Scroll className="Footer-Main-Index-Link" to="Contact" smooth={true} duration={500}>
            <Box>CONTACT</Box>
          </Scroll>
          <Link
            className="Footer-Main-Index-Link"
            target="_blank"
            href="/resources/情報セキュリティ基本方針_第1版.pdf"
            rel="noopener noreferrer"
            component="a"
          >
            <Box>情報セキュリティ基本方針</Box>
          </Link>
        </Box>
      </Box>
      <Box className="Footer-Copyright">© Techno Standard, Inc.</Box>
    </Box>
  );
};

const sx: SxProps<Theme> = (theme) => ({
  "&.Footer": {
    width: "100%",
  },
  ".Footer-Main": {
    background: "#373636",
    color: "#FFFFFF",
  },
  ".Footer-Main-Tecno-Logo": {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    paddingTop: "68px",
  },
  ".Footer-Main-Post": {
    margin: "16px auto",
    textAlign: "center",
  },
  ".Footer-Main-Index": {
    display: "flex",
    flexDirection: {
      md: "row",
      xs: "column",
    },
    alignItems: "center",
    justifyContent: "center",
    padding: {
      md: "32px 0  64px",
      xs: "48px 0  0",
    },
  },
  ".Footer-Main-Index-Link": {
    color: "#FFFFFF",
    textDecoration: "none",
    border: {
      md: "none",
      xs: "1px solid rgba(247, 248, 248, 0.3)",
    },
    width: {
      md: "auto",
      xs: "100%",
    },
    textAlign: "center",
    margin: {
      md: "0px 28px",
    },
    padding: {
      md: "0",
      xs: "24px 0",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  ".Footer-Copyright": {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    lineHeight: "20px",
    fontWeight: 400,
    margin: {
      md: "32px auto 60px",
      xs: "20px auto",
    },
  },
});
